
import React from 'react'
import { Navbar, Nav, Image, Button } from 'react-bootstrap'

import { useAuth } from "../contexts/FirebaseAuth"

import "./TopNav.css"


export default function TopNav() {
    const { currentUser } = useAuth()
    //const [expanded, setExpanded] = useState(false)

    return (
        <Navbar  // controls nav background color
            // fixed="top" // keeps nav at top (not necessary when sticky top is set)
            // sticky="top" // pushes page below, prevents overlap when expanded
            // collapse when less wide than this breakpoint
            collapseOnSelect // collapse after a nav link has been clicked
            style={{ marginBottom: "15px", backgroundColor: `rgba(0,0,0,0)` }}

        // OVERRIDING DEFAULT BEHAVIOR SO WE CAN DETECT CURRENT TOGGLED STATE
        //expanded={expanded}
        //onToggle={next => setExpanded(next)}
        >
            <Navbar.Brand href="/" style={{
                fontSize: 22,
                fontFamily: `'Titan One', cursive`,
                color: (
                    (
                        (window.location.href.indexOf('/profile') > -1)
                        ||
                        (window.location.href.indexOf('/login') > -1)
                    )
                        ?
                        'black'
                        :
                        'white'
                )
            }}>Much Pic</Navbar.Brand>

            <Navbar.Toggle aria-controls="basic-navbar-nav" />

            <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                    {
                        !(window.location.href.indexOf('/login') > -1)
                        &&

                        !currentUser &&
                        <Button variant='dark' href="/login">Iniciar Sesión</Button>


                    }
                </Nav>

                {currentUser &&
                    <Nav.Link href="/profile" className="profile-link">
                        <Image roundedCircle src={currentUser.photoURL} alt="user profile" height="35px" />
                    </Nav.Link>
                }
            </Navbar.Collapse>


        </Navbar>
    )
}
