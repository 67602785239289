

import React from 'react'
import { Container, Card, Button, Form } from 'react-bootstrap'
//import GoogleButton from 'react-google-button'
import { useHistory } from 'react-router-dom'
import firebase from "firebase/app"

import app from "../firebase"
import { useFlashUpdate } from "../contexts/FlashContext"
import imgGoogle from "../images/google.png"

export default function GoogleLogin() {
    const flash = useFlashUpdate()
    const history = useHistory()

    async function handleLogin(event) {
        event.preventDefault()

        var provider = new firebase.auth.GoogleAuthProvider()
        provider.addScope("https://www.googleapis.com/auth/userinfo.email") // see: https://developers.google.com/identity/protocols/oauth2/scopes

        const result = await app.auth().signInWithPopup(provider)
        //const result = await app.auth().signInWithRedirect(provider)

        var user = result.user
        var providerId = result.additionalUserInfo.providerId
        var profile = result.additionalUserInfo.profile
        var token = result.credential.accessToken
        console.log("USER:", user) // user.uid, user.displayName, user.email, user.emailVerified, user.phoneNumber, user.photoURL, user.refreshToken
        console.log("PROVIDER:", providerId)
        console.log("USER PROFILE:", profile)
        console.log("ACCESS TOKEN", token)
        // TODO: store this user info in the database, perhaps firestore

        console.log("LOGIN SUCCESS")
        // flash({message:"Login success. Welcome back!", variant: "success"})
        history.push("/profile")
    }

    return (
        <div>
            <img onClick={handleLogin} src={imgGoogle} style={{
                position: 'fixed',
                top: (window.innerHeight / 2) - 75,
                left: (window.innerWidth / 2) - 75,
                width: 150
            }} />
        </div>
    )
}
