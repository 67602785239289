

import React, { Component, useState } from 'react'
import ReactGA from 'react-ga'
import { Container, Jumbotron, Button, CardGroup, Card, Row, Col, Modal } from 'react-bootstrap'
import { useAuth } from "../contexts/FirebaseAuth"

import BackgroundImage1 from '../images/panelled.jpeg'
import BackgroundImage2 from '../images/foto.jpeg'
import Camara from '../images/camara.png'
import image1 from '../images/imagen.png'

//import { useAuth } from "../contexts/FirebaseAuth"

export default function Home() {
    const [show, setShow] = useState(false);
    //const { currentUser } = useAuth()

    const { currentUser } = useAuth()


    const handleClick = (event) => {
        // console.log("YOU CLICKED TO LEARN MORE!")
        // ReactGA.event({ category: "Learn More Button", action: "Click" })
    }

    return (
        <Container style={{
            position: 'fixed',
            top: 0,
            left: 0,
            zIndex: -1,
            // [this.state.side]: this.state.size,
            backgroundColor: '#000',
            minWidth: '100%',
            minHeight: '100%'
        }}>

            <Row style={{ height: '100vh', overflow: 'scroll' }}>

                <Col md={6} style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh'
                }}>
                    <div style={{ backgroundImage: `url(${BackgroundImage1})`, backgroundPosition: 'center', backgroundSize: 'cover', height: '100%', opacity: 0.3, position: 'absolute', width: '100%' }}></div>
                    <div style={{
                        margin: 0, minWidth: 0, alignItems: 'center', justifyContent: 'center', position: 'relative', top: 0, transition: 'top 0.3s ease-in-out', zIndex: 3, display: 'flex', flexDirection: "column", textAlign: 'center', paddingLeft: 32, paddingRight: 32, maxWidth: 530
                    }}>
                        <div style={{
                            paddingLeft: 32, paddingRight: 32, maxWidth: 530
                        }}>
                            <img src={Camara} style={{ display: 'block', height: 80, margin: 0, width: 80 }}></img>
                        </div>
                        <div style={{
                            marginBottom: 16,
                            marginTop: 32
                        }}>
                            <h1 style={{
                                fontSize: 32,
                                fontFamily: 'unset',
                                color: '#FFF',
                                margin: 0

                            }}>Tu evento al siguente nivel</h1>
                        </div>
                        <div style={{
                            marginBottom: 32,
                        }}>
                            <p style={{ fontSize: 20, color: '#ccc', margin: 0, lineHeight: 1.5 }}>
                                Podés generar un ambiente dinámico e interactivo compartiendo la esencia de tu evento a lo grande!
                            </p>
                        </div>
                        <Button variant="danger" style={{ backgroundColor: "rgb(183 72 64)", borderColor: "rgb(183 72 64)" }} onClick={() => {
                            setShow(true)
                        }}>Conocer más</Button>

                    </div>

                </Col>
                <Col md={6} style={{
                    display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh'
                }}>
                    <div style={{ backgroundImage: `url(${BackgroundImage2})`, backgroundPosition: 'center', backgroundSize: 'cover', height: '100%', opacity: 0.3, position: 'absolute', width: '100%' }}></div>
                    <div style={{
                        margin: 0, minWidth: 0, alignItems: 'center', justifyContent: 'center', position: 'relative', top: 0, transition: 'top 0.3s ease-in-out', zIndex: 3, display: 'flex', flexDirection: "column", textAlign: 'center', paddingLeft: 32, paddingRight: 32, maxWidth: 530
                    }}>
                        <div style={{
                            paddingLeft: 32, paddingRight: 32, maxWidth: 530
                        }}>
                            <img src={image1} style={{ display: 'block', height: 80, margin: 0, width: 80 }}></img>
                        </div>
                        <div style={{
                            marginBottom: 16,
                            marginTop: 32
                        }}>
                            <h1 style={{
                                fontSize: 32,
                                fontFamily: 'unset',
                                color: '#FFF',
                                margin: 0

                            }}>Todos como protagonistas</h1>
                        </div>
                        <div style={{
                            marginBottom: 32,
                        }}>
                            <p style={{ fontSize: 20, color: '#ccc', margin: 0, lineHeight: 1.5 }}>
                                Con Much Pic vas a hacer partícipes a todos tus invitados... ¡Podrán compartir en vivo!
                            </p>
                        </div>
                        <Button variant="danger" style={{ backgroundColor: "rgb(183 72 64)", borderColor: "rgb(183 72 64)" }} href={currentUser ? '/profile' : '/login'} onClick={handleClick}>Comenzar</Button>


                    </div>

                </Col>
            </Row>

            <Modal show={show} onHide={() => setShow(false)}>
                <Modal.Header closeButton={<Button>Volver</Button>}
                    style={{ backgroundColor: "#242323", color: '#fff', border: 'none' }}
                >
                    <Modal.Title>Comparte tu contenido en cualquier pantalla</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#242323", color: '#fff', paddingTop: 0 }}>
                    <div style={{ margin: 15, textAlign: 'center' }}>En 3 pasos...</div>
                    <div>
                        <div style={{ fontSize: 15, fontWeight: 500 }}>CREA TU EVENTO</div>
                        <div style={{ fontSize: 15, fontWeight: 100, marginBottom: 15 }}>Regístrate gratis y crea el evento, solo 1 minuto y vas a estar un paso mas cerca de hacer que tu evento sea especial</div>
                        <div style={{ fontSize: 15, fontWeight: 500 }}>PANTALLA GIGANTE</div>
                        <div style={{ fontSize: 15, fontWeight: 100, marginBottom: 15 }}>Activa la pantalla gigante con el código, para que todos los invitados puedan conectarse</div>
                        <div style={{ fontSize: 15, fontWeight: 500 }}>QUE EMPIECE LA FIESTA</div>
                        <div style={{ fontSize: 15, fontWeight: 100, marginBottom: 15 }}>Todos los usuarios podrán escanear el código en la pantalla gigante, ¡y comenzar a compartir contenido en vivo!</div>
                    </div>
                    <Button variant="danger" style={{ backgroundColor: "rgb(183 72 64)", borderColor: "rgb(183 72 64)" }} href={currentUser ? '/profile' : '/login'} block onClick={handleClick}>Comenzar</Button>


                </Modal.Body>
            </Modal>
        </Container >
    )



}
